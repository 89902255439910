<template>
  <div class="w1200">
    <div class="title_box">
      <span class="title">{{info.serviceName}}</span>
      <span class="back" @click="$router.go(-1)">返回</span>
    </div>
    <div class="serviceDetails" v-html="info.contents"></div>
    <div class="list_box">
      <div class="flex1 list_box_left">
        <div class="sub_title">
          <span>奖项荣誉</span>
        </div>
        <ul class="list">
          <li v-for="item in rongyu" @click="goNewDetails(item)" :key='item.id'>{{item.news_title}}</li>
        </ul>
      </div>
      <div class="flex1 list_box_right">
        <div class="sub_title">
          <span>业绩新闻</span>
        </div>
        <ul class="list">
          <li v-for="item in dongtai" @click="goNewDetails(item)" :key='item.id'>{{item.news_title}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:'',
      info:'',
      rongyu:[],
      dongtai:[]
    }
  },
  mounted(){
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods:{
    getInfo() {
      this.$api.getServiceAreaDetail({id:this.id}).then(res=>{
        this.info = res.data.service
        this.dongtai = res.data.dongtai
        this.rongyu = res.data.rongyu
      })
    },
    goNewDetails(item) {
      this.$router.push({
        path:'/newDetails/'+item.id,
        query:{type:3}
      })
    }
  }
}
</script>
<style>
  .serviceDetails img{
    max-width: 100%;
  }
</style>
<style lang='stylus' scoped >
@import '~@/assets/stylus/variable'
  .title_box
    position: relative;
    height: 48px;
    background: #fafafa;
    margin: 0 0 6% -10px;
    .title
      font-family: 'SourceHanSerifCN-Light';
      display: inline-block;
      line-height: 56px;
      font-size: 24px;
      color: #fff;
      height: 56px;
      position: relative;
      top: -4px;
      padding:0 80px 0 80px
      border-radius: 3px 0 0 3px;
      background: linear-gradient(to right,#72cff4 0%,#1567e2 100%)
    .back
      position: absolute
      right 10px
      top 9px
      border 1px solid #bbb
      padding 8px 20px
      font-size 12px
      color: #222;
      border-radius 20px
      cursor pointer
  .sub_title
    position relative
    margin 20px 0
    span
      display inline-block
      position relative
      width: 130px
      height: 40px
      text-align center
      line-height 40px
      color #fff
      background: linear-gradient(to right,#72cff4 0%,#1567e2 100%)
      font-size 16px
      border-radius 20px
      z-index: 2
    &::before
      display: block
      content: ''
      position: absolute
      left 30px
      width: 130px
      height: 30px
      bottom 0
      background #f4f4f4
      border-radius 20px
    &::after
      display: block
      content: ''
      position: absolute
      left 160px
      bottom 10px
      width: calc(100% - 160px)
      height: 4px
      background #f4f4f4
  .serviceDetails
    font-size 16px
  .list_box
    display flex
  .list_box_left
    margin-right: 20px
  .list_box_right
    margin-left: 20px
  .list
    li
      line-height: 40px
      font-size 16px
      position relative
      padding-left 20px
      margin-bottom 10px
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
      cursor pointer
      &:hover
        color $theme-color
        font-weight: bold
      &::before
        display: block
        content: ''
        position: absolute
        left 0
        width: 5px
        height: 5px
        top 50%
        transform: translateY(-50%)
        background #ccc
        border-radius 50%
</style>